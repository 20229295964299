var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"form",attrs:{"id":"shipping-details-form"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitForm)}}},[_c('div',{staticClass:"form__horizontal"},[_c('ValidationProvider',{staticClass:"form__element",attrs:{"name":"firstname","rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{attrs:{"disabled":_vm.loading,"error-message":_vm.$t(errors[0]),"label":_vm.$t('First Name'),"valid":!errors[0],"name":"firstname","required":""},model:{value:(_vm.form.firstname),callback:function ($$v) {_vm.$set(_vm.form, "firstname", $$v)},expression:"form.firstname"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"form__element",attrs:{"name":"lastname","rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{attrs:{"disabled":_vm.loading,"error-message":_vm.$t(errors[0]),"label":_vm.$t('Last Name'),"valid":!errors[0],"name":"lastname","required":""},model:{value:(_vm.form.lastname),callback:function ($$v) {_vm.$set(_vm.form, "lastname", $$v)},expression:"form.lastname"}})]}}],null,true)})],1),_vm._v(" "),_c('ValidationProvider',{staticClass:"form__element",attrs:{"name":"street","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{attrs:{"disabled":_vm.loading,"error-message":_vm.$t(errors[0]),"label":_vm.$t('Street Name'),"valid":!errors[0],"name":"street","required":""},model:{value:(_vm.form.street),callback:function ($$v) {_vm.$set(_vm.form, "street", $$v)},expression:"form.street"}})]}}],null,true)}),_vm._v(" "),_c('SfInput',{staticClass:"form__element",attrs:{"label":_vm.$t('House/Apartment number'),"name":"apartment","required":""},model:{value:(_vm.form.apartment),callback:function ($$v) {_vm.$set(_vm.form, "apartment", $$v)},expression:"form.apartment"}}),_vm._v(" "),_c('div',{staticClass:"form__horizontal"},[_c('ValidationProvider',{staticClass:"form__element",attrs:{"rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{attrs:{"disabled":_vm.loading,"error-message":_vm.$t(errors[0]),"label":_vm.$t('City'),"valid":!errors[0],"name":"city","required":""},model:{value:(_vm.form.city),callback:function ($$v) {_vm.$set(_vm.form, "city", $$v)},expression:"form.city"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"form__element",attrs:{"rules":!_vm.form.country_code || _vm.regionInformation.length === 0 ? null : 'required|min:2',"name":"region"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(!_vm.form.country_code || _vm.regionInformation.length === 0)?_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('shipping-state'),expression:"'shipping-state'"}],attrs:{"disabled":!_vm.form.country_code || _vm.loading,"error-message":!_vm.form.country_code ? _vm.$t('Please select a country first') : '',"label":_vm.$t('State/Province'),"valid":!!_vm.form.country_code,"name":"state"},model:{value:(_vm.form.region.region),callback:function ($$v) {_vm.$set(_vm.form.region, "region", $$v)},expression:"form.region.region"}}):_c('SfSelect',{directives:[{name:"e2e",rawName:"v-e2e",value:('shipping-state'),expression:"'shipping-state'"}],staticClass:"form__element form__element--half form__element--half-even form__select sf-select--underlined",attrs:{"disabled":_vm.loading,"error-message":_vm.$t(errors[0]),"label":_vm.$t('State/Province'),"valid":!errors[0],"name":"state"},model:{value:(_vm.form.region.region_code),callback:function ($$v) {_vm.$set(_vm.form.region, "region_code", $$v)},expression:"form.region.region_code"}},_vm._l((_vm.regionInformation),function(regionOption){return _c('SfSelectOption',{key:regionOption.id,attrs:{"value":regionOption.abbreviation}},[_vm._v("\n            "+_vm._s(regionOption.label)+"\n          ")])}),1)]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"form__horizontal"},[_c('ValidationProvider',{staticClass:"form__element",attrs:{"name":"postcode","rules":"required|min:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{attrs:{"disabled":_vm.loading,"error-message":_vm.$t(errors[0]),"label":_vm.$t('Zip-code'),"valid":!errors[0],"name":"postcode","required":""},model:{value:(_vm.form.postcode),callback:function ($$v) {_vm.$set(_vm.form, "postcode", $$v)},expression:"form.postcode"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"country_code","rules":"required|min:2","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfSelect',{staticClass:"form__select sf-select--underlined",attrs:{"disabled":_vm.loading,"error-message":_vm.$t(errors[0]),"label":_vm.$t('Country'),"valid":!errors[0],"name":"country","required":""},on:{"input":function($event){return _vm.updateCountry({ id: $event })}},model:{value:(_vm.form.country_code),callback:function ($$v) {_vm.$set(_vm.form, "country_code", $$v)},expression:"form.country_code"}},[_c('SfSelectOption',{attrs:{"value":''}}),_vm._v(" "),_vm._l((_vm.countriesList),function(countryOption){return _c('SfSelectOption',{key:countryOption.id,attrs:{"value":countryOption.abbreviation}},[_vm._v("\n            "+_vm._s(countryOption.label)+"\n          ")])})],2)]}}],null,true)})],1),_vm._v(" "),_c('ValidationProvider',{staticClass:"form__element",attrs:{"name":"telephone","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{attrs:{"disabled":_vm.loading,"error-message":_vm.$t(errors[0]),"label":_vm.$t('Phone number'),"valid":!errors[0],"name":"telephone","required":""},model:{value:(_vm.form.telephone),callback:function ($$v) {_vm.$set(_vm.form, "telephone", $$v)},expression:"form.telephone"}})]}}],null,true)}),_vm._v(" "),_c('SfCheckbox',{staticClass:"form__checkbox-isDefaultShipping",attrs:{"disabled":_vm.loading,"label":_vm.$t('Set as default shipping'),"name":"isDefaultShipping"},model:{value:(_vm.form.default_shipping),callback:function ($$v) {_vm.$set(_vm.form, "default_shipping", $$v)},expression:"form.default_shipping"}}),_vm._v(" "),_c('SfCheckbox',{staticClass:"form__checkbox-isDefaultBilling",attrs:{"disabled":_vm.loading,"label":_vm.$t('Set as default billing'),"name":"isDefaultBilling"},model:{value:(_vm.form.default_billing),callback:function ($$v) {_vm.$set(_vm.form, "default_billing", $$v)},expression:"form.default_billing"}}),_vm._v(" "),_c('SfButton',{staticClass:"form__button",attrs:{"disabled":_vm.loading,"type":"submit"}},[_vm._t("submit-button-content")],2)],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }